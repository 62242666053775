import React from 'react';
import { Col, Row } from 'reactstrap';
import giro from '../../assets/images/logo_giro_fundo_escuro.png';
import './FooterComponent.scss';
class FooterComponent extends React.Component {
  render() {
    return (
      <div className="footer-area">
        <div className="text-area">
          <div className="text">
            {/* <p>Esta operação está sendo realizada em ambiente seguro</p> */}
          </div>
          {/* <img src={lockIcon}></img> */}
        </div>
        <div className="logo-area">
          <Row>
            <Col xs="6" className="text">
              <p>Desenvolvido por</p>
            </Col>
            <Col xs="6" className="icon">
              {/* <a href="https://www.giropagamentos.com.br" target="_blank"> */}
              <img src={giro}></img>
              {/* </a> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default FooterComponent;
